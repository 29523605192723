<template>
  <div>
    <BaseSpinner v-if="loading" :loading="loading" />
    <div v-else class="card mb-3">
      <div class="card-header">
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'endpoints' }"><i class="fal fa-lg fa-chevron-left mr-2"></i> Webhooks</router-link>
          </li>
          <li class="breadcrumb-item active">
            Endpoint
          </li>
        </ul>
      </div>
      <div class="card-body">
        <pre class="rounded p-3">{{ $store.state.user.webhooks.url || 'Empty' }}</pre>
      </div>
      <div class="card-footer">
        <a-dropdown-button size="small" :trigger="['click']" @click="visible = true">
          <EndpointForm
            :endpoint="endpoint"
            :visible="visible"
            @close="visible = false"
            @success="onSuccess"
          >
            <i class="fal fa-fw fa-pen"></i> Edit
          </EndpointForm>
          <a-menu slot="overlay" @click="onClick">
            <a-menu-item key="delete">
              <span class="text-danger"><i class="fal fa-fw fa-minus-circle"></i> Delete</span>
            </a-menu-item>
          </a-menu>
        </a-dropdown-button>
      </div>
    </div>
    <EndpointAttemptList />
  </div>
</template>

<script>
import BaseSpinner from '@/components/Base/BaseSpinner'
import EndpointAttemptList from '@/components/Endpoint/EndpointAttemptList'
import EndpointForm from '@/components/Endpoint/EndpointForm'

export default {
  components: {
    BaseSpinner,
    EndpointForm,
    EndpointAttemptList,
  },

  data() {
    return {
      loading: false,
      visible: false,
      endpoint: null,
    }
  },

  async mounted() {
    await this.retrieveEndpoint()
  },

  methods: {
    async retrieveEndpoint() {
      try {
        this.loading = true

        var { data } = await this.$httpd.get(`/user/endpoints/${ this.$route.params.id }`, {
          params: { livemode: this.$store.state.livemode },
        })

        this.endpoint = data
      } catch (err) {
        if (err.response) {
          if (err.response.status === 404) {
            this.$router.push({ name: '404' })
          }

          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      } finally {
        this.loading = false
      }
    },
    onClick() {
      this.$confirm({
        title: 'Delete Endpoint',
        content: 'This webhook endpoint will be deleted from your account. The action cannot be undone.',
        okText: 'Delete',
        onOk: async () => {
          await this.onDelete()
        },
        onCancel() {},
      })
    },
    async onDelete() {
      try {
        var { data } = await this.$httpd.delete(`/user/endpoints/${ this.endpoint.id }`)

        this.$message.success('Endpoint has been deleted.')
        this.$router.push({ name: 'endpoints' })
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      }
    },
    onSuccess(data) {
      this.endpoint = data
      this.visible = false
    },
  },

  watch: {
    '$store.state.livemode'() {
      this.$router.replace({ name: 'endpoints' })
    },
  },
}
</script>
