<template>
  <div>
    <div v-if="$store.state.livemode && !$store.getters.isPaid" class="alert alert-warning mb-2">
      To add an endpoint, upgrade to the <strong>Small Business</strong> or <strong>Enterprise</strong> plan.
      <router-link :to="{ name: 'subscription' }" class="ml-2">Upgrade</router-link>
    </div>
    <div class="card mb-3">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-6">
            <strong>Webhooks</strong>
          </div>
          <div class="col-6 text-right">
            <ul class="list-inline mb-0">
              <li class="list-inline-item">
                <a href="https://vatstack.com/docs/webhooks" target="_blank" class="mr-2">API Reference <i class="fal fa-external-link ml-1"></i></a>
              </li>
              <li v-if="!endpoints.length" class="list-inline-item">
                <EndpointForm :visible="visible" @close="visible = false" @success="onSuccess">
                  <a-button type="primary" :disabled="$store.state.livemode && !$store.getters.isPaid" @click="visible = true">
                    <i class="fal fa-plus mr-2"></i> Add Endpoint
                  </a-button>
                </EndpointForm>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <BaseSpinner :loading="loading">
        <div
          v-for="(endpoint, i) in endpoints"
          :key="endpoint.id"
          class="card-body"
          :class="{ 'border-bottom': i < endpoints.length - 1 }"
          style="cursor: pointer;"
          @click="$router.push({ name: 'endpoints-id', params: { id: endpoint.id }})"
        >
          <div class="row justify-content-between align-items-center">
            <div class="col-auto">
              <label class="label">Endpoint URL</label>
              <div>
                <code>{{ endpoint.url }}</code>
              </div>
              <small>
                <p class="text-muted mb-0">Subscribing to {{ endpoint.events.length }} events.</p>
              </small>
            </div>
            <div class="col-auto">
              <i class="fal fa-chevron-right"></i>
            </div>
          </div>
        </div>
        <div v-if="!endpoints.length && !loading" class="card-body text-muted text-center">
          <i class="fad fa-empty-set mr-1"></i> No endpoints have been added yet.
        </div>
      </BaseSpinner>
      <div class="card-footer">
        <small>
          <p class="text-muted mb-0">Webhooks allow you to send events to other services. This can be useful if you need to process Vatstack data on a different platform, or to validate VAT ID numbers asynchronously when government services are unavailable.</p>
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSpinner from '@/components/Base/BaseSpinner'
import EndpointForm from '@/components/Endpoint/EndpointForm'

export default {
  components: {
    BaseSpinner,
    EndpointForm,
  },

  data() {
    return {
      loading: true,
      visible: false,
      endpoints: [],
      endpointsCount: null,
    }
  },

  mounted() {
    this.listEndpoints()
  },

  methods: {
    async listEndpoints() {
      try {
        this.loading = true

        var { data } = await this.$httpd.get(`/user/endpoints`, {
          headers: { 'x-api-key': this.$store.getters.secretKey },
          params: { livemode: this.$store.state.livemode },
        })

        this.endpoints = data.endpoints
        this.endpointsCount = data.endpoints_count
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      } finally {
        this.loading = false
      }
    },
    onSuccess(data) {
      this.$router.push({ name: 'endpoints-id', params: { id: data.id }})
      this.visible = false
    },
  },

  watch: {
    '$store.state.livemode'() {
      this.listEndpoints()
    },
  },
}
</script>
