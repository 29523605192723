<template>
  <div>
    <slot />
    <a-modal
      :confirmLoading="loading"
      okText="Save"
      :title="endpoint ? 'Edit Endpoint': 'Create Endpoint'"
      :visible="visible"
      @cancel="$emit('close')"
      @ok="onConfirm"
    >
      <p>Before you update the endpoint, ensure that it listens to <span class="badge badge-success">POST</span> requests and that it returns status code <span class="badge badge-success">2XX</span>.</p>
      <a-form :form="form" @submit.prevent.native="onConfirm">
        <a-form-item label="URL" :colon="false">
          <a-input
            name="url"
            placeholder="https://example.com/vatstack"
            type="text"
            v-decorator="['url']"
          />
        </a-form-item>
        <a-form-item label="Events" :colon="false">
          <a-checkbox-group
            name="events"
            v-decorator="['events']"
          >
            <div><a-checkbox value="batch.succeeded"><code>batch.succeeded</code></a-checkbox></div>
            <div><a-checkbox value="batch.failed"><code>batch.failed</code></a-checkbox></div>
            <div><a-checkbox value="validation.succeeded"><code>validation.succeeded</code></a-checkbox></div>
            <div><a-checkbox value="validation.failed"><code>validation.failed</code></a-checkbox></div>
          </a-checkbox-group>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    endpoint: Object,
    visible: Boolean,
  },

  data() {
    return {
      loading: false,
    }
  },

  created() {
    this.form = this.$form.createForm(this, {
      mapPropsToFields: () => {
        if (!this.endpoint) return

        return {
          events: this.$form.createFormField({ value: this.endpoint.events }),
          url: this.$form.createFormField({ value: this.endpoint.url }),
        }
      },
    })
  },

  methods: {
    onConfirm() {
      this.form.validateFields(async (err, values) => {
        if (err) {
          return false
        }

        this.loading = true

        try {
          if (this.endpoint) {
            var { data } = await this.$httpd.put(`/user/endpoints/${ this.endpoint.id }`, {
              livemode: this.$store.state.livemode,
              ...values,
            })
          } else {
            var { data } = await this.$httpd.post(`/user/endpoints`, {
              livemode: this.$store.state.livemode,
              ...values,
            })
          }

          this.$emit('success', data)
          this.$message.success('Endpoint has been saved.')
        } catch (err) {
          if (err.response) {
            this.$message.error(err.response.data.message)
          } else {
            this.$message.error(err.message)
          }

          return false
        } finally {
          this.loading = false
        }
      })
    },
  },
}
</script>
