<template>
  <div class="card mb-3">
    <div class="card-header">
      <strong>Webhook Attempts</strong>
    </div>
    <BaseSpinner :loading="loading">
      <table class="table mb-0">
        <thead class="thead-light">
          <tr>
            <th>Event</th>
            <th>Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="attempt in attempts" :key="attempt.id">
            <td>
              <code>{{ attempt.event.name }}</code>
            </td>
            <td>
              {{ convertDate(attempt.created) }}
            </td>
            <td>
              <span v-if="/^2/.test(attempt.status_code)" class="badge badge-success mr-1">{{ attempt.status_code }}</span>
              <span v-else-if="/^3/.test(attempt.status_code)" class="badge badge-warning mr-1">{{ attempt.status_code }}</span>
              <span v-else class="badge badge-danger mr-1">{{ attempt.status_code }}</span>
              {{ attempt.status_text }} ({{ attempt.response_time }} ms)
            </td>
          </tr>
          <tr v-if="!attempts.length && !loading">
            <td class="text-muted text-center" colspan="3">
              <i class="fad fa-empty-set mr-1"></i> No webhook attempts have been performed yet.
            </td>
          </tr>
        </tbody>
      </table>
    </BaseSpinner>
    <div class="card-footer">
      <BasePagination
        v-model="filters.page"
        :hasMore="hasMore"
        :pageCount="Math.ceil(attemptsCount / filters.limit)"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import BasePagination from '@/components/Base/BasePagination'
import BaseSpinner from '@/components/Base/BaseSpinner'

export default {
  components: {
    BasePagination,
    BaseSpinner,
  },

  data() {
    return {
      loading: true,
      hasMore: false,
      filters: {
        endpoint: this.$route.params.id,
        limit: 20,
        page: Number(this.$route.query.page) || 1,
      },
      attempts: [],
      attemptsCount: null,
    }
  },

  mounted() {
    this.listAttempts()
  },

  methods: {
    async listAttempts() {
      this.loading = true

      try {
        var { data } = await this.$httpd.get(`/attempts`, {
          params: this.filters
        })
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      } finally {
        this.loading = false
      }

      this.hasMore = data.has_more
      this.attempts = data.attempts
      this.attemptsCount = data.attempts_count
    },

    convertDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm')
    },
  },

  watch: {
    'filters.page'(value) {
      this.$router.push({ name: 'endpoints-id', query: { page: value }})
    },
  },
}
</script>
